<template>
  <v-container pt-0 fluid>
    <v-layout row wrap pb-3>
      <div id="ordersReportsSearchFixed">
        <div class="searchFixed">
          <v-card outlined elevation="2" class="ma-3">
            <v-card-text>
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$t('actions.search')"
                single-line
                :color="inputColor"
                hide-details
              />
            </v-card-text>
          </v-card>
        </div>
      </div>
      <v-flex xs12>
        <v-card
          elevation="12"
        >
          <v-toolbar flat color="primary">
            <v-toolbar-title class="yellow-color-text">
              {{ $t(`pages.${$route.name}`) }}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-layout row wrap>
              <v-flex xs12 sm6 px-2>
                <v-dialog
                  ref="start"
                  v-model="modal"
                  :return-value.sync="reportObject.startDate"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="reportObject.startDate"
                      :label="$t(`reports.startDate`)" 
                      :disabled="!isEditting"
                      :color="inputColor"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker v-model="reportObject.startDate" scrollable>
                    <v-spacer />
                    <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.start.save(reportObject.startDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>
              <v-flex xs12 sm6 px-2>
                <v-dialog
                  ref="dialog2"
                  v-model="modalEnd"
                  :return-value.sync="reportObject.endDate"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="reportObject.endDate"
                      :label="$t(`reports.endDate`)" 
                      :disabled="!isEditting"
                      :color="inputColor"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker v-model="reportObject.endDate" :min="reportObject.startDate" scrollable>
                    <v-spacer />
                    <v-btn text color="primary" @click="modalEnd = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.dialog2.save(reportObject.endDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>
              <v-flex xs12 sm6 px-2>
                <v-autocomplete
                  v-model="reportObject.categories"
                  :multiple="true"
                  :items="categoryOrders"
                  :label="$t(`reports.categories`)" 
                  :color="inputColor"
                  item-text="name"
                  item-value="id"
                />
              </v-flex>
              <v-flex xs12 sm6 px-2>
                <v-autocomplete
                  v-model="reportObject.installers"
                  :multiple="true"
                  :items="installers"
                  :label="$t(`reports.installers`)" 
                  :color="inputColor"
                  item-text="name"
                  item-value="id"
                />
              </v-flex>
              <v-flex v-if="reportObject.categories == 1" xs12 sm6 px-2>
                <v-autocomplete
                  v-model="reportObject.fieldBatches"
                  :multiple="true"
                  :items="fieldBatchesOptions"
                  :label="$t(`reports.fieldBatchesIds`)" 
                  :color="inputColor"
                  item-text="name"
                  item-value="id"
                  @change="bagsOptionsAdjust"
                />
              </v-flex>
              <v-flex v-if="reportObject.categories == 1" xs12 sm6 px-2>
                <v-autocomplete
                  v-model="reportObject.products"
                  :multiple="true"
                  :items="products"
                  :label="$t(`reports.products`)" 
                  :color="inputColor"
                  item-text="name"
                  item-value="id"
                />
              </v-flex>

              <v-flex v-if="reportObject.categories == 1" xs12 sm6 px-2>
                <v-autocomplete
                  v-model="reportObject.cycles"
                  :multiple="true"
                  :items="cycles"
                  :label="$t(`reports.cycles`)" 
                  :color="inputColor"
                  item-text="name"
                  item-value="id"
                />
              </v-flex>
              
              <v-flex v-if="reportObject.categories == 1" xs12 sm6 px-2>
                <v-autocomplete
                  v-model="reportObject.kits"
                  :multiple="true"
                  :items="kits"
                  :label="$t(`reports.kits`)" 
                  :color="inputColor"
                  item-text="reference"
                  item-value="id"
                />
              </v-flex>
            </v-layout>
          </v-card-text>
          
          <v-card-actions>
            <a-button :state="buttonState" color="primary" @click="send">{{ $t('actions.executeReport') }}</a-button>
            <v-spacer />
            <!-- <a-button v-if="retrieved" :state="buttonState" color="primary" @click="executeReport">{{ $t('actions.exportReport') }}</a-button> -->
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex xs12 class="my-4">
        <v-card
          v-if="true"
          id="ordersReportsCard"
          elevation="primary"
        >
          <!-- <v-layout slot="header" row wrap justify-space-between align-center>
            <h4 style="padding: 0; margin: 0;">{{ $t(`pages.${$route.name}Preview`) }}</h4>
          </v-layout> -->
          <v-toolbar flat color="primary">
            <v-toolbar-title class="yellow-color-text">
              {{ $t(`pages.${$route.name}Preview`) }}
            </v-toolbar-title>
            <v-spacer/>
            <export-button :headers="headers.filter((header) => header.value != 'actions')" :items="orders" :search="search" :file-name="'Ordenes historicas'"></export-button>
          </v-toolbar>
          <v-card-text>
            <v-text-field
              id="ordersReportsSearch"
              v-model="search"
              append-icon="search"
              :label="$t('actions.search')"
              single-line
              :color="inputColor"
              hide-details
            />
            
            <v-data-table
              fixed-header
              height="800"
              :headers="headers"
              :items="orders"
              :search="search"
              :items-per-page="-1"
              :footer-props="{
                'next-icon': 'mdi-chevron-right',
                'prev-icon': 'mdi-chevron-left'
              }"
            >
              <template v-slot:body="{ items }">
                <tr v-for="item in items" :key="item.id">
                  <td class="text-xs-right; font-weight: bold;">
                    {{ item.id }}
                  </td>
                  <td>{{ item.executionDate }}</td>
                  <td>{{ item.categoryOrderName }}</td>
                  <td>{{ item.fieldBatchName }}</td>
                  <td>{{ item.productName }}</td>
                  <td>{{ item.cycleName }}</td>
                  <td style="text-align: right; padding: 0;">
                    <v-btn
                      text
                      icon
                      rounded
                      color="info"
                      @click="showItem(item.id)"
                    >
                      <v-icon>mdi-file-find-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
              <tr slot="no-results">
                <td :colspan="headers.length" v-html="$t('helpers.notFound', { search })" />
              </tr>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog
      id="orderModal"
      v-model="orderModal"
      scrollable
      persistent
      :fullscreen="isFullScreen"
      :overlay="false"
      :max-width="width.dialog"
      transition="dialog-transition"
      :dark="dark"
    >
      <v-card>
        <!-- <v-card-title class="headline primary elevation-3" style="text-align: center; max-height: 58px;"><h5 class="white--text ma-0">{{ title }}</h5></v-card-title> -->
        <v-toolbar flat color="primary">
          <v-toolbar-title class="yellow-color-text">
            {{ title }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-layout row wrap pa-1 align-center justify-center>
            <v-flex class="pl-1 pr-1" xs12 :class="object.categoryOrderId === '1' ? 'sm6' : ''">
              <v-select
                v-model="object.categoryOrderId"
                :items="categoryOrders"
                :label="$t(`${objectName.multiple}.categoryOrderId`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="name"
                item-value="id"
                :error="objectErrors.categoryOrder !== undefined"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId == '1'" class="pl-1 pr-1" xs12 sm6>
              <v-select
                v-model="object.cycleId"
                :items="cycles"
                :label="$t(`${objectName.multiple}.cycleId`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="name"
                :error="objectErrors.cycle !== undefined"
                item-value="id"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId == '1'" class="pl-1 pr-1" xs12 sm6>
              <v-select
                v-model="object.productId"
                :items="products"
                :label="$t(`${objectName.multiple}.productId`)" 
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.product !== undefined"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId == '1'" class="pl-1 pr-1" xs12 sm6>
              <v-select
                v-model="object.fieldBatchId"
                :items="fieldBatches"
                :label="$t(`${objectName.multiple}.fieldBatchId`)" 
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.fieldBatch !== undefined"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex class="pl-1 pr-1" xs12 sm4>
              <v-select
                v-model="object.installerId"
                :items="installers"
                :label="$t(`${objectName.multiple}.installerId`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex class="pl-1 pr-1" xs12 sm4>
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="object.executionDate"
                persistent
                lazy
                full-width
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="object.executionDate"
                    :label="$t(`${objectName.multiple}.executionDate`)" 
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.executionDate !== undefined"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="object.executionDate" scrollable>
                  <v-spacer />
                  <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
                  <v-btn flat color="primary" @click="$refs.dialog.save(object.executionDate)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-flex>
            <v-flex class="pl-1 pr-1" xs12 sm4>
              <v-text-field
                v-model="object.bagsQuantity"
                type="number"
                :label="$t(`${objectName.multiple}.bagsQuantity`)" 
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.bagsQuantity !== undefined"
                @change="adjustBags"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId !== '2'" class="pl-1 pr-1" xs12>
              <v-select
                v-model="object.kitsIds"
                :items="selectableKits()"
                :multiple="true"
                :label="$t(`${objectName.multiple}.kitsIds`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="helper"
                item-value="id"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId !== '2'" class="pl-1 pr-1" xs12>
              <v-select
                v-model="object.sensors"
                :items="selectableLoraSensors()"
                :multiple="true"
                :label="$t(`${objectName.multiple}.sensors`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="deviceEui"
                item-value="id"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId !== '2'" class="pl-1 pr-1" xs12>
              <v-select
                v-model="object.bluetoothSensors"
                :items="selectableBtSensors()"
                :multiple="true"
                :label="$t(`${objectName.multiple}.bluetoothSensors`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="sensorId"
                item-value="id"
              />
            </v-flex>
            <v-flex v-if="object.categoryOrderId === '1'" xs12>
              <v-data-table
                :headers="bagHeaders"
                :items="object.bags"
                :search="search"
                :items-per-page="-1"
                :footer-props="{
                  'next-icon': 'mdi-chevron-right',
                  'prev-icon': 'mdi-chevron-left'
                }"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.id">
                      <td>
                        <v-text-field
                          v-model="item.reference"
                          :label="$t(`bags.reference`)" 
                          :disabled="!isEditting"
                          :color="inputColor"
                        />
                      </td>
                      <td>
                        <v-text-field
                          v-model="item.estimatedWeight"
                          :label="$t(`bags.estimatedWeight`)"
                          type="number"
                          min="0" 
                          :disabled="!isEditting"
                          :color="inputColor"
                        />
                      </td>                
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-flex>
            <v-flex v-if="object.categoryOrderId !== '1'" class="pl-1 pr-1" xs12>
              <v-select
                v-model="object.bagsIds"
                :items="bags"
                :multiple="true"
                :label="$t(`${objectName.multiple}.bagsIds`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="reference"
                item-value="id"
              />
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions v-if="isEditting" style="max-height: 70px;">
          <v-btn color="error" @click="discardChanges">{{ $t('actions.discard') }}</v-btn>

          <v-spacer />
        </v-card-actions>

        <v-card-actions v-else style="max-height: 70px;">
          <v-btn color="error" @click="discardChanges">{{ $t('actions.close') }}</v-btn>
          
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AButton from '@/components/Button.vue'
import ExportButton from '@/components/utils/ExportButton.vue'

import { mapState, mapGetters } from 'vuex'

import orderList from '@/graphql/queries/reports/orderList.gql'
import object from '@/graphql/queries/orders/detail.gql'

import generateReport from '@/graphql/mutations/reports/generateReport.gql'
import moment from 'moment'
import ApolloClient from 'apollo-boost'

const objectName = {
  single: 'order',
  multiple: 'orders',
  object: {
    single: 'Order',
    multiple: 'Orders'
  }
}

export default {
  components: {
    AButton,
    ExportButton
  },

  data () {
    return {
      orderModal: false,
      orders: [],
      messages: [],
      modal: false,
      modalEnd: false,
      objectName,
      keys: { primary: 'name', secondary: 'deviceEui' },
      i18n: this.$i18n.t('duallist3'),
      dense: true,
      drawingManager: null,
      pagination: { rowsPerPage: 20 },
      formModal: false,
      isEditting: true,
      deleteDialog: false,
      showPassword: false,
      deleteConfirm: false,
      buttonState: false,
      deleteId: 0,
      map: null,
      geofence: null,
      drawer: null,
      retrieved: false,
      fieldBatchesOptions: [],
      bagsOptions: [],
      objects: {
        id: undefined,
        categoryOrderId: 0,
        cycleId: 0,
        productId: 0,
        fieldBatchId: 0,
        installerId: 0,
        executionDate: moment().format('YYYY-MM-DD'),
        sensors: [],
        bluetoothSensors: [],
        bagsQuantity: 0,
        kitsIds: [],
        bags: [],
        bagsIds: []
      },
      reportObject: {
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        categories: [],
        installers: [],
        fieldBatchesIds: [],
        products: [],
        cycles: [],
        kits: []
      },
      object: {},
      objectErrors: {},
      search: '',
      usableKits: [],
      usableBtSensors: [],
      usableLoraSensors: [],
      objectErrors: {},
      search: '',
      events: [
        {
          id: 0,
          name: 'Reporte Periódico Normal'
        },
        {
          id: 10,
          name: 'Reporte Magnético'
        },
        {
          id: 20,
          name: 'Reporte Movimiento'
        },
        {
          id: 100,
          name: 'Falla Reporte Periódico'
        }
      ],
      headers: [
        { 
          align: 'left',
          sortable: true,
          value: 'id',
          text: this.$i18n.t(`${objectName.multiple}.id`),
          format: (val) => `${val}`
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.executionDate`),
          value: 'executionDate',
          format: (val) => `${val}`
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.categoryOrderId`),
          value: 'categoryOrderName' ,
          format: (val) => `${val}`
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.fieldBatchId`),
          value: 'fieldBatchName' ,
          format: (val) => `${val}`
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.cycleId`),
          value: 'cycleName',
          format: (val) => `${val}`
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.fieldBatchId`),
          value: 'fieldBatchName',
          format: (val) => `${val}`
        },
        {
          text: this.$i18n.t('helpers.actions'),
          align: 'right',
          sortable: false,
          value: 'actions',
        }
      ],
      bagHeaders: [
        {
          text: this.$i18n.t(`bags.reference`)
        },
        {
          text: this.$i18n.t(`bags.estimatedWeight`)
        }
      ]
    }
  },

  computed: {
    ...mapState(['user', 'bags', 'width', 'dark', 'language', 'categoryOrders', 'cycles', 'products', 'fieldBatches', 'installers', 'loraSensors', 'btSensors', 'kits']),
    // ...mapState({ data: objectName.multiple }),
    ...mapGetters(['inputColor', 'loraSensorsBtSensors', 'freeKitsLabeled', 'ownedKitsLabeled', 'freeBtSensors', 'freeLoraSensors']),
    ...mapGetters({
      data: 'noFinishedOrders'
    }),
    isMobile () {
      return this.width.full < 600
    },
    isFullScreen () {
      return this.width.full < 990
    },

    title () {
      if (!this.isEditting) {
        return this.$i18n.t(`${objectName.multiple}.titleShow`, { name: this.object.reference })
      } else {
        if (this.object.id === undefined) {
          return this.$i18n.t(`${objectName.multiple}.titleNew`)
        } else {
          return this.$i18n.t(`${objectName.multiple}.titleEdit`, { name: this.object.reference })
        }
      }
    }
  },

  created () {
    this.fieldBatchesOptions = this.fieldBatches
    this.bagsOptions = this.bags
    window.addEventListener('scroll', this.handleScroll)
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },

  mounted () {
    const card = document.querySelector('#ordersReportsCard')
    const search = document.getElementById('ordersReportsSearchFixed')

    var observer = new ResizeObserver(function (entries) {
      entries.forEach(function (entry) {
        search.style.width = `${card.offsetWidth}px`
      })
    })

    observer.observe(card)
  },

  methods: {
    handleScroll (event) {
      const elem = document.getElementById('ordersReportsCard')
      const search = document.getElementById('ordersReportsSearchFixed').childNodes[0]
      const searchCard = document.getElementById('ordersReportsSearch')

      var posTopView = window.scrollY
      var elemTop = elem.offsetTop + 126

      if (elemTop > posTopView) {
        search.classList.remove('visible')
        if (this.keyFocus) {
          searchCard.focus()
          this.keyFocus = false
        }
      } else {
        search.classList.add('visible')
        this.keyFocus = true
      }
    },
    discardChanges () {
      this.usableKits = []
      this.usableLoraSensors = []
      this.usableBtSensors = []
      this.objectErrors = {}
      this.object = {}
      this.object = Object.assign({}, this.defaultObject)
      this.orderModal = false
      this.isEditting = false
    },
    selectableKits () {
      const kits = this.ownedKitsLabeled.filter((item) => this.usableKits.includes(item.id))
      const finalKits = [
        ...this.freeKitsLabeled,
        ...kits
      ]
      return finalKits
    },
    selectableBtSensors () {
      const btSensors = this.btSensors.filter((item) => this.usableBtSensors.includes(item.id))
      const finalBtSensors = [
        ...this.freeBtSensors,
        ...btSensors
      ]
      return finalBtSensors
    },
    selectableLoraSensors () {
      const loraSensors = this.loraSensors.filter((item) => this.usableLoraSensors.includes(item.id))
      const finalLoraSensors = [
        ...this.freeLoraSensors,
        ...loraSensors
      ]
      return finalLoraSensors
    },
    adjustBags () {
      const bags = []
      for (var i = 0; i < this.object.bagsQuantity; i++) {
        if (this.object.bags[i] === undefined) {
          bags.push({
            reference: 'Nueva Bolsa',
            estimatedWeight: 0
          })
        } else {
          bags.push(this.object.bags[i])
        }
      }

      this.object.bags = bags
    },
    fieldBatchesOptionsAdjust () {
      this.object.fieldBatchesIds = []
      this.object.bagsIds = []
      if (this.object.loraNodesIds.length !== 0) {
        this.fieldBatchesOptions = this.fieldBatches.filter((field) => this.object.loraNodesIds.includes(field.loraNodeId))
      } else {
        this.fieldBatchesOptions = this.fieldBatches
      }
      if (this.object.loraNodesIds.length !== 0) {
        const fieldBatchesIds = this.fieldBatches.filter((field) => this.object.loraNodesIds.includes(field.loraNodeId)).map((field) => field.id)
        
        this.bagsOptions = this.bags.filter((bag) => fieldBatchesIds.includes(bag.fieldBatchId))
      } else {
        this.bagsOptions = this.bags
      }
    },

    bagsOptionsAdjust () {
      this.object.bagsIds = []

      if (this.object.fieldBatchesIds.length !== 0) {
        this.bagsOptions = this.bags.filter((bag) => this.object.fieldBatchesIds.includes(bag.fieldBatchId))
      } else {
        if (this.object.loraNodesIds.length !== 0) {
          const fieldBatchesIds = this.fieldBatches.filter((field) => this.object.loraNodesIds.includes(field.loraNodeId)).map((field) => field.id)
          
          this.bagsOptions = this.bags.filter((bag) => fieldBatchesIds.includes(bag.fieldBatchId))
        } else {
          this.bagsOptions = this.bags
        }
      }
    },
    executeReport () {
      this.buttonState = true
      const variables = {
        ...this.reportObject
      }

      const mutation = generateReport
      const mutationName = 'generateReport'

      this.$apollo.mutate({
        mutation,
        variables
      }).then(response => {
        const { status, errors, result } = response.data[mutationName]

        if (status === 'OK') {
          window.open('/generated/' + result, '_blank')
        } else {
          this.$store.commit('toggleAlert', {
            type: 'warning',
            message: this.$i18n.t('errors.invalidFields')
          })
        }
      }).catch(error => {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      }).finally(() => {
        this.buttonState = false
      })
    },
    async send () {
      const client = new ApolloClient({
        uri: '/graphql'
      })
      
      await client.query({
        query: orderList,
        variables: {
          startDate: moment(this.reportObject.startDate).unix(),
          endDate: moment(this.reportObject.endDate).unix(),
          categories: this.reportObject.categories,
          installers: this.reportObject.installers,
          fieldBatches: this.reportObject.fieldBatchesIds,
          products: this.reportObject.products,
          cycles: this.reportObject.cycles,
          kits: this.reportObject.kits,
          apiToken: this.user.apiToken,
          language: this.language.toUpperCase()
        },
        fetchPolicy: 'no-cache'
      }).then((response) => {
        const { orders, status } = response.data.reportsOrders
        
        if (status === 'OK') {
          this.orders = orders
          this.retrieved = true
        } else {
          
        }
      }).catch((error) => {
      })
    },
    date (date) {
      return moment(date).format('YYYY-MM-DD')
    },

    time (time) {
      return moment(time).format('HH:mm:ss')
    },
    
    updateMessages () {
      this.$store.dispatch('getMessages')
    },

    async showItem (id) {
      await this.$apollo.query({
        query: object,
        variables: {
          id,
          apiToken: this.user.apiToken,
          language: this.language.toUpperCase()
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const result = response.data[objectName.single]
        
        if (result.status) {
          this.object = {
            ...result[objectName.single]
          }
          this.orderModal = true
          this.isEditting = false
          
          this.usableKits = this.object.kitsIds
          this.usableLoraSensors = this.object.sensors
          this.usableBtSensors = this.object.bluetoothSensors
        } else {
          if (errors.limit === undefined) {
            this.$store.commit('toggleAlert', {
              type: 'error',
              message: (errors.id === undefined ? errors.apiToken : errors.id)
            })
          } else {
            this.$store.commit('toggleAlert', {
              type: 'error',
              message: errors.limit
            })
          }
        }
      }).catch(error => {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      })
    }
  }
}
</script> 