import ApolloClient from 'apollo-boost'
import Cookies from 'js-cookie'

// => Queries and fragments
import login from '@/graphql/queries/utils/auth.gql'
import logout from '@/graphql/mutations/authentication/logout.gql'
import users from '@/graphql/queries/users/list.gql'
import usersSelect from '@/graphql/queries/users/listSelect.gql'
import installers from '@/graphql/queries/installers/list.gql'
import companyCentrals from '@/graphql/queries/companyCentrals/list.gql'
import companyPromotors from '@/graphql/queries/companyPromotors/list.gql'
import companyLocals from '@/graphql/queries/companyLocals/list.gql'
import companyDepositories from '@/graphql/queries/companyDepositories/list.gql'
import categoryIncidents from '@/graphql/queries/categoryIncidents/list.gql'
import categoryAnalyses from '@/graphql/queries/categoryAnalyses/list.gql'
import products from '@/graphql/queries/products/list.gql'
import cycles from '@/graphql/queries/cycles/list.gql'
import loraNodes from '@/graphql/queries/loraNodes/list.gql'
import loraSensors from '@/graphql/queries/loraSensors/list.gql'
import fieldBatches from '@/graphql/queries/fieldBatches/list.gql'
import btSensors from '@/graphql/queries/btSensors/list.gql'
import bags from '@/graphql/queries/bags/list.gql'
import orders from '@/graphql/queries/orders/list.gql'
import categoryOrders from '@/graphql/queries/categoryOrders/list.gql'
import messages from '@/graphql/queries/messages/list.gql'
import messagesMonitor from '@/graphql/queries/messages/listMonitor.gql'
import messagesReport from '@/graphql/queries/messages/reportList.gql'
import categoryAlerts from '@/graphql/queries/categoryAlerts/list.gql'
import presetRuleNotifications from '@/graphql/queries/presetRuleNotifications/list.gql'
import incidents from '@/graphql/queries/incidents/list.gql'
import priorities from '@/graphql/queries/priorities/list.gql'
import kits from '@/graphql/queries/kits/list.gql'
import notificationGroups from '@/graphql/queries/groupNotifications/list.gql'
import companyLocalLinkRequests from '@/graphql/queries/companyLocalLinkRequests/list.gql'
import updateUserConfiguration from '@/graphql/mutations/utils/updateUserConfiguration.gql'

const client = new ApolloClient({
  uri: '/graphql'
})

export default {
  toggleDark (context) {
    context.commit('setDark', !context.state.dark)
  },

  signOut (context, payload) {
    context.commit('setLoading', true)

    client.mutate({
      mutation: logout,
      variables: {
        apiToken: context.state.user.apiToken,
        language: context.state.language.toUpperCase()
      }
    }).then(response => {
      const { status } = response.data.logout

      if (status === 'OK') {
        Cookies.remove('apiToken')

        context.commit('setUser', {
          id: 0,
          name: '',
          apiToken: ''
        })

        clearInterval(context.state.intervalId)
        context.commit('setIntervalId', undefined)
        
        clearInterval(context.state.intervalAllId)
        context.commit('setIntervalAllId', undefined)
        context.commit('setSidebar', true)
        let prepend = 'Platform'
        if (payload.router.currentRoute.path.includes('CMS')) {
          prepend = 'CMS'
        }
        payload.router.push(`/${prepend}/Sessions/New`)
      } else {
        context.commit('toggleAlert', {
          type: 'error',
          message: payload.i18n.t('errors.internalError')
        })
      }
    }).catch(error => {
      context.commit('toggleAlert', {
        type: 'error',
        message: payload.i18n.t('errors.internalError')
      })
    }).finally(() => {
      context.commit('setLoading', false)
    })
  },

  async getUser (context, payload) {
    const apiToken = await Cookies.get('apiToken')

    context.commit('setButtonState', true)
    context.commit('setLoading', true)

    await client.query({
      query: login,
      variables: {
        apiToken,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then(response => {
      const { status, auth } = response.data.auth

      if (status === 'OK') {
        context.commit('setUser', auth)
        if (payload.lastUrl !== '/Platform/Sessions/New') {
          context.dispatch('loadData', payload)
        } else {
          context.commit('setLoading', false)
        }
      } else {
        context.commit('setLoading', false)
        sessionStorage.removeItem('apiToken')
      }
    }).catch(error => {
      context.commit('toggleAlert', {
        type: 'error',
        message: payload.i18n.t('errors.internalError'),
        options: {
          left: false,
          right: false,
          top: false,
          bottom: true
        }
      })
    }).finally(() => {
      context.commit('setButtonState', false)
    })
  },

  async loadData (context, payload) {
    if (context.state.user.object == 'Administrator') {
      await context.dispatch('getPresetRuleNotifications')
    }
    if (['Administrator', 'Central', 'Promotor', 'Local', 'Depository'].includes(context.state.user.object)) {
      await context.dispatch('getUsers')
      await context.dispatch('getCategoryAlerts')
      await context.dispatch('getPriorities')
      // await context.dispatch('getIncidents')
      if (['Central', 'Local', 'Promotor'].includes(context.state.user.object)) {
        await context.dispatch('getInstallers')
      }
      
      if (payload.lastUrl === undefined) {
        payload.router.push('/CMS/Home')
      } else {
        payload.router.push(payload.lastUrl)
      }
    } else {
      if (['Company::Central', 'Company::Local', 'Company::Promotor', 'Company::Depository'].includes(context.state.user.parentType)) {
        if (payload.lastUrl === '/Platform/Home' && context.state.user.parentType !== 'Company::Central') {
          payload.lastUrl = '/Platform/Bags'
        }
        context.dispatch('getMessagesMonitor')
        await context.dispatch('getLoraNodes')
        await context.dispatch('getUsersSelect')
        await context.dispatch('getLoraSensors')
        await context.dispatch('getBtSensors')
        await context.dispatch('getCycles')
        await context.dispatch('getFieldBatches')
        await context.dispatch('getProducts')
        await context.dispatch('getKits')
        await context.dispatch('getNotificationGroups')
        await context.dispatch('getCompanyLocalLinkRequests')
        context.dispatch('getBags')
        context.dispatch('getCategoryOrders')
        context.dispatch('getMessages')
        // if (context.state.user.parentType !== 'Company::Depository') {
        //   context.dispatch('getMessagesReport')
        // }
        context.dispatch('getCategoryAlerts')
        context.dispatch('getPriorities')
        context.dispatch('getIncidents')
        if (context.state.user.parentType == 'Company::Central' && context.state.user.usersAccess) {
          await context.dispatch('getUsers')
        }

      }

      switch (context.state.user.parentType) {
        case 'Administrator':
          await context.dispatch('getCompanyCentrals')
          await context.dispatch('getCompanyPromotors')
          await context.dispatch('getCompanyLocals')
          await context.dispatch('getCompanyDepositories')


          if (payload.lastUrl === undefined) {
            payload.router.push('/Platform/Home')
          } else {
            payload.router.push(payload.lastUrl)
          }

          break

        case 'Company::Central':
          await context.dispatch('getCompanyPromotors')
          await context.dispatch('getCompanyLocals')
          await context.dispatch('getCompanyDepositories')
          await context.dispatch('getCategoryIncidents')
          await context.dispatch('getCategoryAnalyses')
          await context.dispatch('getInstallers')
          await context.dispatch('getOrders')
          if (payload.lastUrl === undefined) {
            payload.router.push('/Platform/Home')
          } else {
            payload.router.push(payload.lastUrl)
          }

          break

        case 'Company::Promotor':
          await context.dispatch('getCompanyLocals')
          await context.dispatch('getCompanyDepositories')
          await context.dispatch('getCategoryIncidents')
          await context.dispatch('getCategoryAnalyses')

          await context.dispatch('getFieldBatches')
          if (payload.lastUrl === undefined) {
            payload.router.push('/Platform/Bags')
          } else {
            payload.router.push(payload.lastUrl)
          }

          break

        case 'Company::Local':
          await context.dispatch('getCompanyDepositories')
          await context.dispatch('getCategoryAnalyses')
          await context.dispatch('getInstallers')

          await context.dispatch('getOrders')
          if (payload.lastUrl === undefined) {
            payload.router.push('/Platform/Bags')
          } else {
            payload.router.push(payload.lastUrl)
          }

          break

        default:
          if (payload.lastUrl === undefined) {
            payload.router.push('/Platform/Bags')
          } else {
            payload.router.push(payload.lastUrl)
          }

          break
      }
    }

    // setTimeout(() => { context.commit('setLoading', false) }, 5000)
    await context.commit('setLoading', false)
  },

  toggleSidebar (context, payload) {
    sessionStorage['sidebarStatus'] = payload
    context.commit('toggleSidebar', payload)
  },

  async getCompanyLocalLinkRequests (context) {
    await client.query({
      query: companyLocalLinkRequests,
      variables: {
        apiToken: context.state.user.apiToken,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      let { status, companyLocalLinkRequests } = response.data.companyLocalLinkRequests
      console.log(companyLocalLinkRequests)
      if (status === 'OK') {
        context.commit('setCompanyLocalLinkRequests', companyLocalLinkRequests)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async getBags (context) {
    await client.query({
      query: bags,
      variables: {
        apiToken: context.state.user.apiToken,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      let { status, bags } = response.data.bags

      if (status === 'OK') {
        context.commit('setBags', bags)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async getBtSensors (context) {
    await client.query({
      query: btSensors,
      variables: {
        apiToken: context.state.user.apiToken,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      let { status, btSensors } = response.data.btSensors

      if (status === 'OK') {
        context.commit('setBtSensors', btSensors)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async getLoraSensors (context) {
    await client.query({
      query: loraSensors,
      variables: {
        apiToken: context.state.user.apiToken,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      let { status, loraSensors } = response.data.loraSensors

      if (status === 'OK') {
        context.commit('setLoraSensors', loraSensors)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async getFieldBatches (context) {
    await client.query({
      query: fieldBatches,
      variables: {
        apiToken: context.state.user.apiToken,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      let { status, fieldBatches } = response.data.fieldBatches

      if (status === 'OK') {
        context.commit('setFieldBatches', fieldBatches)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async getLoraNodes (context) {
    await client.query({
      query: loraNodes,
      variables: {
        apiToken: context.state.user.apiToken,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      let { status, loraNodes } = response.data.loraNodes

      if (status === 'OK') {
        context.commit('setLoraNodes', loraNodes)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async getUsers (context) {
    await client.query({
      query: users,
      variables: {
        apiToken: context.state.user.apiToken,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      let { status, users } = response.data.users

      if (status === 'OK') {
        context.commit('setUsers', users)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },


  async getUsersSelect (context) {
    await client.query({
      query: usersSelect,
      variables: {
        apiToken: context.state.user.apiToken,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      let { status, users } = response.data.usersSelect

      if (status === 'OK') {
        context.commit('setUsersSelect', users)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async getInstallers (context) {
    await client.query({
      query: installers,
      variables: {
        apiToken: context.state.user.apiToken,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      let { status, installers } = response.data.installers

      if (status === 'OK') {
        context.commit('setInstallers', installers)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async getCompanyCentrals (context) {
    await client.query({
      query: companyCentrals,
      variables: {
        apiToken: context.state.user.apiToken,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      let { status, companyCentrals } = response.data.companyCentrals

      if (status === 'OK') {
        context.commit('setCompanyCentrals', companyCentrals)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async getCompanyPromotors (context) {
    await client.query({
      query: companyPromotors,
      variables: {
        apiToken: context.state.user.apiToken,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      let { status, companyPromotors } = response.data.companyPromotors

      if (status === 'OK') {
        context.commit('setCompanyPromotors', companyPromotors)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async getCompanyLocals (context) {
    await client.query({
      query: companyLocals,
      variables: {
        apiToken: context.state.user.apiToken,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      let { status, companyLocals } = response.data.companyLocals

      if (status === 'OK') {
        context.commit('setCompanyLocals', companyLocals)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async getCompanyDepositories (context) {
    await client.query({
      query: companyDepositories,
      variables: {
        apiToken: context.state.user.apiToken,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      let { status, companyDepositories } = response.data.companyDepositories

      if (status === 'OK') {
        context.commit('setCompanyDepositories', companyDepositories)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async getCategoryIncidents (context) {
    await client.query({
      query: categoryIncidents,
      variables: {
        apiToken: context.state.user.apiToken,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      let { status, categoryIncidents } = response.data.categoryIncidents

      if (status === 'OK') {
        context.commit('setCategoryIncidents', categoryIncidents)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async getCategoryAnalyses (context) {
    await client.query({
      query: categoryAnalyses,
      variables: {
        apiToken: context.state.user.apiToken,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      let { status, categoryAnalyses } = response.data.categoryAnalyses
      if (status === 'OK') {
        context.commit('setCategoryAnalyses', categoryAnalyses)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async getProducts (context) {
    await client.query({
      query: products,
      variables: {
        apiToken: context.state.user.apiToken,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      let { status, products } = response.data.products

      if (status === 'OK') {
        context.commit('setProducts', products)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async getCycles (context) {
    await client.query({
      query: cycles,
      variables: {
        apiToken: context.state.user.apiToken,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      let { status, cycles } = response.data.cycles

      if (status === 'OK') {
        context.commit('setCycles', cycles)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async deleteItem (context, payload) {
    let mutation = require(`@/graphql/mutations/${(payload.objectField === undefined ? payload.object : payload.objectField)}/delete.gql`)

    await client.mutate({
      mutation,
      variables: {
        ...payload.variables,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then(response => {
      let data = response.data[payload.mutation.graphql]

      if (data.status === 'OK') {
        context.dispatch(payload.mutation.vuex)
      } else {
        if (data.errors.id === undefined) {
          context.commit('toggleAlert', { type: 'error' })
        } else {
          context.commit('toggleAlert', {
            type: 'error',
            message: data.errors.id
          })
        }
      }
    }).catch(error => {
      context.commit('toggleAlert', {
        type: 'error'
      })

      console.error(error)
    })
  },

  async getOrders (context) {
    await client.query({
      query: orders,
      variables: {
        apiToken: context.state.user.apiToken,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      const { status, orders } = response.data.orders

      if (status === 'OK') {
        context.commit('setOrders', orders)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async getCategoryOrders (context) {
    await client.query({
      query: categoryOrders,
      variables: {
        apiToken: context.state.user.apiToken,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      const { status, categoryOrders } = response.data.categoryOrders

      if (status === 'OK') {
        context.commit('setCategoryOrders', categoryOrders)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async getMessages (context) {
    await client.query({
      query: messages,
      variables: {
        apiToken: context.state.user.apiToken,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      const { status, messages } = response.data.messages

      if (status === 'OK') {
        context.commit('setMessages', messages)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async getMessagesMonitor (context) {
    if (context.state.user.apiToken === undefined || context.state.user.apiToken == '') {
      return
    }
    await client.query({
      query: messagesMonitor,
      variables: {
        apiToken: context.state.user.apiToken,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      const { status, nodes, loraSensors, btSensors, alerts } = response.data.messagesMonitor
      if (status === 'OK') {
        context.commit('setMessagesMonitor', [nodes, loraSensors, btSensors])
        context.commit('setAlerts', alerts)
      } else {
        // context.commit('toggleAlert', {
        //   type: 'error'
        // })
      }
    }).catch((error) => {
      // console.log(error)
      // context.commit('toggleAlert', {
      //   type: 'error'
      // })
    })
  },

  async getMessagesReport (context) {
    await client.query({
     query: messagesReport,
        variables: {
          apiToken: context.state.user.apiToken,
          language: context.state.language.toUpperCase()
        },
        fetchPolicy: 'no-cache'
    }).then((response) => {
      
      let { status, messages } = response.data.messagesReport
      if (status === 'OK') {
        context.commit('setMessagesReport', messages)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async getCategoryAlerts (context) {
    await client.query({
     query: categoryAlerts,
        variables: {
          apiToken: context.state.user.apiToken,
          language: context.state.language.toUpperCase()
        },
        fetchPolicy: 'no-cache'
    }).then((response) => {
      let { status, categoryAlerts } = response.data.categoryAlerts

      if (status === 'OK') {
        context.commit('setCategoryAlerts', categoryAlerts)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async getPresetRuleNotifications (context) {
    await client.query({
     query: presetRuleNotifications,
        variables: {
          apiToken: context.state.user.apiToken,
          language: context.state.language.toUpperCase()
        },
        fetchPolicy: 'no-cache'
    }).then((response) => {
      let { status, presetRuleNotifications } = response.data.presetRuleNotifications

      if (status === 'OK') {
        context.commit('setPresetRuleNotifications', presetRuleNotifications)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async getIncidents (context) {
    await client.query({
     query: incidents,
        variables: {
          apiToken: context.state.user.apiToken,
          language: context.state.language.toUpperCase()
        },
        fetchPolicy: 'no-cache'
    }).then((response) => {
      let { status, incidents } = response.data.incidents

      if (status === 'OK') {
        context.commit('setIncidents', incidents)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async getPriorities (context) {
    await client.query({
      query: priorities,
      variables: {
        apiToken: context.state.user.apiToken,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      const { status, priorities } = response.data.priorities

      if (status === 'OK') {
        context.commit('setPriorities', priorities)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async getKits (context) {
    await client.query({
      query: kits,
      variables: {
        apiToken: context.state.user.apiToken,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      let { status, kits } = response.data.kits

      if (status === 'OK') {
        context.commit('setKits', kits)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async getNotificationGroups (context) {
    await client.query({
      query: notificationGroups,
      variables: {
        apiToken: context.state.user.apiToken,
        language: context.state.language.toUpperCase()
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      const { status, nodeAlerts, bagAlerts } = response.data.notificationGroups
      console.log(response.data.notificationGroups)
      if (status === 'OK') {
        context.commit('setNodeNotificationGroups', nodeAlerts)
        context.commit('setBagNotificationGroups', bagAlerts)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  },

  async saveUserConfiguration (context, payload) {
    const configuration = Object.assign({}, context.state.user.configuration)
    configuration[payload.key] = payload.value
    console.log(configuration)
    await client.mutate({
      mutation: updateUserConfiguration,
      variables: {
        apiToken: context.state.user.apiToken,
        object: configuration,
        language: context.state.language.toUpperCase()
      },
    }).then((response) => {
      const { status, result } = response.data.updateUserConfiguration
      if (status === 'OK') {
        console.log(result)
      } else {
        context.commit('toggleAlert', {
          type: 'error'
        })
      }
    }).catch((error) => {
      console.log(error)
      context.commit('toggleAlert', {
        type: 'error'
      })
    })
  }
}
